import axios from '@/plugins/axios';
import { OPERATION_TYPE, OPERATOR_TYPE } from './constants';

class DeviceDistributeLogApi {
  static getLogs(
    page = 1,
    limit = 10,
    type = OPERATION_TYPE.ALL,
    optGroupType = OPERATOR_TYPE.ALL,
    productNo = '',
    produceNo = '',
    optNickName = '',
  ) {
    return axios({
      url: '/api/sys/log/product/distribute/page',
      data: {
        page,
        limit,
        type,
        opt_group_type: optGroupType, // TODO: 接口缺失参数，响应字段同时缺失
        product_no: productNo,
        produce_no: produceNo,
        opt_nick_name: optNickName,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询设备流转日志',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/sys/log/product/distribute/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default DeviceDistributeLogApi;
