<template>
  <div class="device-distribute-log">
    <app-query-bar
      :queryConfig="queryConfig"
      v-model="queryData"
      @onSearch="fetchLogs" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="LOG_COLUMNS"
      :data="logs"
      v-model="queryData"
      @onPageConfigChange="fetchLogs">
      <template
        slot-scope="{ row }"
        slot="type">
        {{ BASIC_OPERATION_TYPE_TEXT[row.type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="optGroupType">
        {{ BASIC_OPERATOR_TYPE_TEXT[row.opt_group_type] }}
      </template>
    </app-data-table>
  </div>
</template>

<script>
import {
  LOG_COLUMNS,
  OPERATION_TYPE,
  BASIC_OPERATION_TYPE_TEXT,
  OPERATION_TYPE_OPTIONS,
  OPERATOR_TYPE,
  BASIC_OPERATOR_TYPE_TEXT,
  OPERATOR_TYPE_OPTIONS,
} from './constants';
import DeviceDistributeLogApi from './device-distribute-log.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'device-distribute-log',
  data() {
    return {
      LOG_COLUMNS,
      BASIC_OPERATION_TYPE_TEXT,
      BASIC_OPERATOR_TYPE_TEXT,
      queryConfig: [
        // {
        //   fieldName: 'productNo',
        //   name: '设备编号',
        // },
        {
          fieldName: 'produceNo',
          name: '生产编号',
        },
        {
          fieldName: 'type',
          name: '操作类型',
          type: 'select',
          options: OPERATION_TYPE_OPTIONS,
        },
        {
          fieldName: 'optGroupType',
          name: '操作人类型',
          type: 'select',
          options: OPERATOR_TYPE_OPTIONS,
        },
        {
          fieldName: 'optNickName',
          name: '操作人',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        type: OPERATION_TYPE.ALL,
        optGroupType: OPERATOR_TYPE.ALL,
        optNickName: '',
        productNo: '',
        produceNo: '',
      },
      logs: [],
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      const {
        page,
        limit,
        type,
        optGroupType,
        optNickName,
        productNo,
        produceNo,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await DeviceDistributeLogApi.getLogs(
        page,
        limit,
        type,
        optGroupType,
        productNo,
        produceNo,
        optNickName,
      );
      this.logs = results;
      this.$set(this.queryData, 'count', count);
    },
    async onExport() {
      const {
        page,
        limit,
        type,
        optGroupType,
        optNickName,
        productNo,
        produceNo,
      } = this.queryData;
      const key = await DeviceDistributeLogApi.export({
        page,
        limit,
        type,
        opt_group_type: optGroupType,
        product_no: productNo,
        produce_no: produceNo,
        opt_nick_name: optNickName,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await DeviceDistributeLogApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style scoped>

</style>
