import { render, staticRenderFns } from "./device-distribute-log.vue?vue&type=template&id=6f63089c&scoped=true&"
import script from "./device-distribute-log.vue?vue&type=script&lang=js&"
export * from "./device-distribute-log.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f63089c",
  null
  
)

export default component.exports